import {
  API_PATH_CATEGORIES as CATEGORIES,
  API_PARAM_CATEGORY_ID as CATEGORY_ID,
  API_PATH_CURRENT as CURRENT,
  API_PATH_PRIOR as PRIOR,
  API_PATH_REPORT as REPORT,
  API_PATH_REPORTS as REPORTS,
  API_PARAM_REPORT_ID as REPORT_ID,
  API_PATH_SECONDARY as SECONDARY,
  API_PATH_SFR as SFR,
  API_PATH_SFR_CONFIG as SFR_CONFIG,
  API_PATH_STX as STX,
  API_PARAM_SUBMISSION_ID as SUBMISSION_ID,
  API_PATH_SURVEYS as SURVEYS,
  API_PARAM_SURVEY_PERIOD_ID as SURVEY_PERIOD_ID
} from '@ps-monorepo/api-constants';

// Some environmental config constants
export const ENV = { baseUrl: '/' };

// App Routes - Dashboard
export const RTE_DASHBOARD = '/';

// App Routes - SFR
export const RTE_SFR_CONFIG = `/${SFR}/${SFR_CONFIG}`;
export const RTE_SFR = `/${SFR}/${CURRENT}`;
export const RTE_SFR_SUB_CAT_SURVEY_PRD = `/${SFR}/${CURRENT}/${CATEGORIES}/:${CATEGORY_ID}/${SURVEYS}/:${SURVEY_PERIOD_ID}`;
export const RTE_SFR_SUB_CAT_SURVEY_PRD_REPORTS = `/${SFR}/${CURRENT}/${CATEGORIES}/:${CATEGORY_ID}/${SURVEYS}/:${SURVEY_PERIOD_ID}/${REPORTS}`;
export const RTE_SFR_SUB_CAT_SURVEY_PRD_REPORT = `/${SFR}/${CURRENT}/${CATEGORIES}/:${CATEGORY_ID}/${SURVEYS}/:${SURVEY_PERIOD_ID}/${REPORTS}/:${REPORT_ID}`;

// App Routes - SFR Prior Years
export const RTE_SFR_PRIOR_YRS = `/${SFR}/${PRIOR}`;
export const RTE_SFR_PRIOR_YRS_SUB_CAT_SURVEY_PRD_REPORTS = `/${SFR}/${PRIOR}/${CATEGORIES}/:${CATEGORY_ID}/${SURVEYS}/:${SURVEY_PERIOD_ID}/${REPORTS}`;
export const RTE_SFR_PRIOR_YRS_SUB_CAT_SURVEY_PRD_REPORT = `/${SFR}/${PRIOR}/${CATEGORIES}/:${CATEGORY_ID}/${SURVEYS}/:${SURVEY_PERIOD_ID}/${REPORTS}/:${REPORT_ID}`;

// App Routes - Secondary Reports
export const RTE_SECONDARY_REPORTS = `/${SECONDARY}`;
export const RTE_SECONDARY_REPORT_RUN = `/${SECONDARY}/:${SUBMISSION_ID}/`;
export const RTE_SECONDARY_REPORT_EDIT = `/${SECONDARY}/:${SUBMISSION_ID}/${REPORT}`;

// App Routes - STX
export const RTE_STX = `/${STX}`;

// Demo/Dev Routes
export const RTE_DEMO_SSE = '/demo-sse';
export const RTE_DEMO_FASTER = '/demo-faster';
export const RTE_DEMO_TYPE_AHEAD_WIDGET = '/demo-type-ahead-widget';

// PDS Class Constants
export const PDS_IS_ACTIVE = 'pds-is-active';
export const PDS_TABS_PANEL = 'pds-tabs-panel';
